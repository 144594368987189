import { Button, Icon, Input, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { useState } from 'react';

import { CashierBalances } from '../../components';

import css from './cashier-till.module.css';

export function CashierTillPage() {

    const [isTillOpened, setIsTillOpened] = useState(false);
    const [closeMode, setCloseMode] = useState(false);

    return <div className={css.container}>

        {isTillOpened && !closeMode && <CashierBalances />}

        {
            !isTillOpened &&
            <div className={css.openTillButtonWrapper}>
                <Button size='huge' secondary onClick={() => setIsTillOpened(true)}>
                    <Icon name='unlock alternate' />
                    Ouvrir ma caisse <Icon name='arrow alternate circle right outline' />
                </Button>
            </div>
        }

        {
            isTillOpened && !closeMode &&
            <div className={css.openedTillContainer}>

                <Button size='huge' secondary onClick={() => setCloseMode(true)}>
                    <Icon name="lock" /> Clôturer ma caisse
                </Button>
            </div>
        }

        {
            closeMode &&
            <span>
                {/* <h2>Clôturer ma caisse pour la journée</h2> */}
                <br />
                <Table basic='very'>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell className='size-18'>Monnaie</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Solde veille</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Appro</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Encaissement</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Décaissement</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Solde calculé</TableHeaderCell>
                            <TableHeaderCell className='size-18'>Image/PDF</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <img src='/images/wave.jpg' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/orangemoney.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                      
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/freemoney.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                      
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/kash-kash.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/kpay.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/western-union.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell>
                                <label className={css.uploadButton}><span className='mr-10'>Joindre un fichier</span> <img src='/images/upload1.png' alt='' width={27} /></label>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/moneygram.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell><label className={css.uploadButton}><span className='mr-10'>Joindre un fichier</span> <img src='/images/upload1.png' alt='' width={27} /></label></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/ria.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell><label className={css.uploadButton}><span className='mr-10'>Joindre un fichier</span> <img src='/images/upload1.png' alt='' width={27} /></label></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='size-15'>
                                <img src='/images/smallworld.png' alt='' width={42} height={40} className='radius-4' />
                            </TableCell>
                            <TableCell className='size-15'>10 000</TableCell>
                            <TableCell className='size-15'>2 000</TableCell>
                            <TableCell className='size-15'><Input placeholder='montant encaissé' /></TableCell>
                            <TableCell className='size-15'><Input placeholder='montant décaissé' /></TableCell>
                            <TableCell className='size-15'></TableCell>
                            <TableCell><label className={css.uploadButton}><span className='mr-10'>Joindre un fichier</span> <img src='/images/upload1.png' alt='' width={27} /></label></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br />
                <br />
                <br />
                <div>
                    <div>
                        <Button size='huge' color='orange' onClick={() => setCloseMode(false)}>
                            <Icon name='window close' />
                            Annuler la cloture
                        </Button>
                        <Button size='huge' secondary onClick={() => setCloseMode(false)}>
                            Clôturer la caisse
                        </Button>
                    </div>
                </div>
            </span>
        }
    </div>
}