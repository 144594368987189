import React from 'react';

import css from './cashier-balances.module.css';

export function CashierBalances() {
    return <div className={css.container}>

        <div className={css.balance}>
            <img src='/images/wave.jpg' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/orangemoney.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/western-union.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/freemoney.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/moneygram.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/kash-kash.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/kpay.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/ria.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        <div className={css.balance}>
            <img src='/images/smallworld.png' alt='' width={48} height={50} className='radius-4'/>
            <h5 className='mt-0 ml-5'>1 000 000 Fcfa</h5>
        </div>
        
            
    
    </div>
}